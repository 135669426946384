import React from "react";
import styles from "./ButtonHideTheory.module.css";
import { ReactComponent as Badge } from "../../assets/icons/chrome_reader_mode-black-18dp.svg";

const ButtonHideTheory = ({
  toggleTheoryAndTask,
  isTheoryAndTaskShow,
  clientWidth,
}) => {
  return (
    <button onClick={toggleTheoryAndTask} className={styles.button}>
      <Badge className={styles.badge} />
      {isTheoryAndTaskShow ? (
        clientWidth > 900 ? (
          <span>Скрыть задание</span>
        ) : (
          <span>Решать задачу</span>
        )
      ) : (
        clientWidth > 900 ? (
          ''
        ) : (
          <span>Показать задание</span>
        )
      )}
    </button>
  );
};

export default ButtonHideTheory;
