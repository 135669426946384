import React from "react";
import { Resizable } from "re-resizable";

const style = {
  width: "50%",
  backgroundColor: "#f7f7f7",
};

const ResizableWrapper = ({ children }) => (
  <Resizable
    style={style}
    defaultSize={{
      width: "55%",
      //   height: "38vh",
    }}
    minWidth="40%"
    maxWidth="55%"
    // maxHeight="50vh"
    // minHeight="50vh"
    // lockAspectRatio="true"
    // bounds="parent"
    enable={{
      top: false,
      right: true,
      bottom: false,
      left: false,
      topRight: false,
      bottomRight: false,
      bottomLeft: false,
      topLeft: false,
    }}
  >
    {children}
  </Resizable>
);

export default ResizableWrapper;
