import React, { Component } from "react";
import styles from "./TaskButtons.module.css";
import { ReactComponent as Refresh } from "../../assets/icons/autorenew-black-18dp.svg";
// import { ReactComponent as Movie } from "../../assets/icons/movie-black-18dp.svg";
// import Timer from "../Timer/Timer";
// import VideoModal from "../VideoModal/VideoModal";

class TaskButtons extends Component {
  state = {
    showPreviewModal: false,
    showPreviewModalFirst: false,
    showVideoModal: false,
  };

  showVideoModal = () => {
    const { showTimer, checkUserSeenHelpVideoForTask } = this.props;
    if (!showTimer) {
      this.setState({ showVideoModal: true });
      checkUserSeenHelpVideoForTask();
    }
  };
  closeVideoModal = () => this.setState({ showVideoModal: false });

  onClickButtonCheckTest = () => {
    const {clientWidth, checkTest} = this.props;
    if (clientWidth < 900) {
        const halfOneSecondInMs = 800;
        setTimeout(() => {
          checkTest();
        }, halfOneSecondInMs)
    } else {
      checkTest();
    }
  };

  render() {
    const {
      // timerEndTime,
      // metadata,
      // showTimer,
      // hideTimer,
      clientWidth,
      // isTheoryAndTaskShow,
      currentTaskId,
      blockTasksArr,
      openModalRefreshQuestion,
      openCongratsModal,
      taskSuccessfullyPassed,
      getNextTask,

      showResultArea,
      hideResultArea,
      isShowResultArea,
    } = this.props;

    const isLastQuestion =
      blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1;

    return (
      <div
        style={{ margin: isShowResultArea ? "5px 0" : "0" }}
        className={styles.buttonsContainer}
      >
        {!taskSuccessfullyPassed && (
          <button className={styles.checkButton} onClick={this.onClickButtonCheckTest}>
            Проверить
          </button>
        )}

        {taskSuccessfullyPassed && !isLastQuestion && (
          <button className={styles.checkButton} onClick={getNextTask}>
            Следующая задача
          </button>
        )}

        {taskSuccessfullyPassed && isLastQuestion && (
          <button className={styles.checkButton} onClick={openCongratsModal}>
            Завершить
          </button>
        )}

        <button
          onClick={openModalRefreshQuestion}
          className={styles.buttonWithSvg}
        >
          <Refresh className={styles.svg} />
          Сбросить
        </button>

        {clientWidth > 1200 && (
          <button
            onClick={isShowResultArea ? hideResultArea : showResultArea}
            className={styles.buttonWithoutSvg}
          >
            {isShowResultArea
              ? "Скрыть секцию результатов"
              : "Показать секцию результатов"}
          </button>
        )}
        {/* {(clientWidth > 1300 || !showTimer) && (
          <button
            onClick={this.showVideoModal}
            className={
              !showTimer ? styles.buttonWithSvg : styles.disableButtonWithSvg
            }
          >
            <Movie className={!showTimer ? styles.svg : styles.disableSvg} />
            Видеоподсказка
          </button>
        )} */}

        {/* {showTimer && (
          <Timer timerEndTime={timerEndTime} hideTimer={hideTimer} />
        )}

        {showVideoModal && (
          <VideoModal metadata={metadata} onClose={this.closeVideoModal} />
        )} */}
      </div>
    );
  }
}

export default TaskButtons;
