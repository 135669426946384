import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./CongratsModal.module.css";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";
import utilsFunctionBlockDefinition from "../../utils/blockDefinition";

const CongratsModal = ({
  onClose,
  blockTasksArr,
  block,
  speedResult,
}) => {
  const tasksCount = blockTasksArr.length;

  const blockIndex = utilsFunctionBlockDefinition(block);

  return (
    <ModalWrapper
      onClose={onClose}
      hideCloseButton
    >
      <section className={s.wrapper}>
        <WinnerCup className={s.winnerCup} width="118" />
        <h2 className={s.modalTitle}>ОТЛИЧНАЯ РАБОТА!</h2>
        <Close
          className={s.close}
          onClick={onClose}
        />
        <div className={s.contentWrapper}>
          <p className={s.text}>
            Ты успешно завершил домашнее задание {blockIndex}-го модуля!
          </p>
          <p className={s.text}>Твои результаты:</p>
          <p className={s.result}>
            <span className={s.countTasks}>{tasksCount}</span>
            правильных ответов
          </p>

          {speedResult > 50 && (
            <p className={s.text}>
              Ты справился с заданиями, быстрее чем
              <span className={s.fatText}>{speedResult}% </span>
              студентов твоей группы.
            </p>
          )}
        </div>
      </section>
    </ModalWrapper>
  );
};

export default CongratsModal;
