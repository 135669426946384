import React, { Component } from "react";
import { ReactComponent as Done } from "../../assets/icons/done.svg";
import { ReactComponent as NotDone } from "../../assets/icons/notDone.svg";
import s from "./ResultArea.module.css";

class ResultArea extends Component {
  state = {};

  render() {
    const { failedConditions, successConditions, checkMetadata } = this.props;

    return (
      <section className={s.result_container}>
        <header className={s.header}>
          <h2 className={s.title}>Результаты:</h2>
          <p className={s.text}>
            (обновляются при нажатии на кнопку - Проверить)
          </p>
        </header>

        <div className={s.main}>
          <div
            className={
              checkMetadata.consoleOutput ? s.criteriaSmall : s.criteriaBig
            }
          >
            <ol className={s.list}>
              {failedConditions.length > 0 &&
                failedConditions.map((element) =>
                  element.includes("h5") ? (
                    <li className={s.wrongCriteria} key={element}>
                      <NotDone className={s.criteriaIcon} />{" "}
                      <div
                        class="errorNotCompileContainer"
                        dangerouslySetInnerHTML={{ __html: element }}
                      />
                    </li>
                  ) : (
                    <li className={s.wrongCriteria} key={element}>
                      <NotDone className={s.criteriaIcon} />{" "}
                      <span>{element}</span>
                    </li>
                  )
                )}
            </ol>
            <ol className={s.list}>
              {successConditions.length > 0 &&
                successConditions.map((element) => (
                  <li className={s.rightCriteria} key={element}>
                    <Done className={s.criteriaIcon} /> <span>{element}</span>
                  </li>
                ))}
            </ol>
          </div>

          {checkMetadata.consoleOutput && (
            <div className={s.consoleWrapper}>
              <h2 className={s.consoleTitle}>Результат выполнения кода:</h2>
              <pre className={s.consoleContent}>
                {checkMetadata.consoleOutput}
              </pre>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default ResultArea;
