const blockOneV2 = 'cdefghi150';
const blockTwoV2 = 'defghij151';
const blockThreeV2 = 'efghijk152';
const blockFourV2 = 'fghijkl153';
const blockFiveV2 = 'yz01abc144';

export default function blockDefinition(blockHash) {
  let blockIndex = 0;
  if (blockHash === "cdefghi42" || blockHash === "op6789254" || blockHash === blockOneV2) {
    blockIndex = 1;
  } else if (blockHash === "p67892355" || blockHash === "2345qrs60" || blockHash === blockTwoV2) {
    blockIndex = 2;
  } else if (blockHash === "678923456" || blockHash === "vwxyz0169" || blockHash === blockThreeV2) {
    blockIndex = 3;
  } else if (blockHash === "789234557" || blockHash === "uvwxyz0104" || blockHash === blockFourV2) {
    blockIndex = 4;
  } else if (blockHash === "892345q58" || blockHash === "bcdefgh77" || blockHash === blockFiveV2) {
    blockIndex = 5;
  } else if (blockHash === "92345qr59" || blockHash === "op6789290") {
    blockIndex = 6;
  }

  return blockIndex;
}
