import axios from "axios";

axios.defaults.baseURL = "https://gotest.com.ua/api/v2/";

export const getTasksBlockAndCurrentTask = (token, block) =>
  axios
    .get(`taskblock/get?token=${token}&block=${block}`)
    .catch((err) =>
      console.warn("api getTasksBlockAndCurrentTask error: ", err)
    );

export const getTaskInfo = (token, taskId, language='ru') =>
  axios
    .get(
      `task/getTaskData?token=${token}&taskId=${taskId}&language=${language}`
    )
    .catch((err) => console.warn("api getTaskInfo error: ", err));

export const checkTest = async (
  token,
  taskId,
  solveTimeSeconds,
  code,
  language='ru'
) => {
  var bodyFormData = new FormData();
  bodyFormData.set("token", token);
  bodyFormData.set("taskId", taskId);
  bodyFormData.set("solveTimeSeconds", solveTimeSeconds);
  bodyFormData.set("code", code);
  bodyFormData.set("language", language);
  return axios({
    method: "post",
    url: "https://gotest.com.ua/api/v2/task/check",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).catch((err) => console.warn("api checkTest error: ", err));
};

export const checkUserSeenHelpVideoForTask = async (
  token,
  eventName,
  eventValue
) => {
  const data = { token, eventName, eventValue };
  return axios({
    method: "post",
    url: "https://gotest.com.ua/api/v2/analytics/addUserEvent",
    data,
    headers: { "Content-Type": "application/json" },
  }).catch((err) =>
    console.warn("api checkUserSeenHelpVideoForTask error: ", err)
  );
};

export const needHelpRequest = (token, question, taskId, solutionText) =>
  axios
    .post("groovyscript/execute", {
      token: token,
      filter: "NEED_TASK_HELP",
      query: "needHelp",
      question: question,
      taskId: taskId,
      solutionText: solutionText,
    })
    .catch((err) => console.warn("api needHelpRequest error: ", err));

export const getTaskStatisticsRequest = (token, taskId) =>
  axios
    .post("tguser/taskstat/calculateRatingForUsersUsingFilters", {
      token: token,
      taskId: taskId,
      filters: {
        withSameTag: "true",
      },
    })
    .catch((err) => console.warn("api getTaskStatisticsRequest error: ", err));
