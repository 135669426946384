import React, { useState } from "react";
import s from "./ModalHelp.module.css";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import Button from "../../components/Button/Button";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import * as API from "../../services/api";

const ModalHelp = ({ onClose = () => null, token, currentTaskId, valueCode }) => {
  const [message, setMessage] = useState("");
  const hahdleChangeTextarea = (e) => setMessage(e.target.value);

  const [isFormSuccessSubmit, setIsFormSuccessSubmit] = useState(false);

  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);

  const [isShowErrorEmptyMessage, setIsShowErrorEmptyMessage] = useState(false);

  const handleSubmitForm = (e) => {
    e.preventDefault();

    if (message.length < 5) {
        setIsShowErrorEmptyMessage(true);
        return;
    } else {
        setIsShowErrorEmptyMessage(false);
    }

    API.needHelpRequest(token, message, currentTaskId, valueCode)
      .then((res) => {
        if (res.data.success) {
          setIsFormSuccessSubmit(true);
          setIsShowErrorMessage(false);
        } else {
          setIsShowErrorMessage(true);
        }
      })
      .catch(() => {
        setIsShowErrorMessage(true);
      });
  };

  return (
    <ModalWrapper onClose={onClose} hideCloseButton>
      <section className={s.wrapper}>
        <Close className={s.close} onClick={onClose} />

        {isFormSuccessSubmit ? (
          <>
            {/* form after success submit - start */}
            <h2 className={s.modalTitle}>
            Успешно!
            </h2>
            <div className={s.contentWrapper}>
              <p className={s.text}>
              Спасибо за вопрос, мы скоро ответим Вам!
              </p>
              <div className={s.button_container}>
                <a
                  href="https://t.me/goit_programmer_bot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    // onClickButton={startGameOrContinue}
                    text="Перейти в бот"
                    bigButton={true}
                    type="button"
                  />
                </a>
              </div>
            </div>
            {/* form before success submit - end */}
          </>
        ) : (
          <>
            {/* form before success submit - start */}
            <h2 className={s.modalTitle}>
              Появились вопросы, предложения? Мы на связи!
            </h2>
            <div className={s.contentWrapper}>
              <p className={s.text}>
              Вопросы, пожелания и рациональные предложения к разработчикам веб-приложения автопроверки домашних заданий по JavaScript (для улучшения: задания, критериев проверки задания, интерфейса):
              </p>

              <form onSubmit={handleSubmitForm}>
                <textarea
                  className={s.textarea}
                  value={message}
                  onChange={hahdleChangeTextarea}
                  cols="30"
                  rows="5"
                  placeholder="Введите текст сообщения"
                  autoFocus
                />
                {isShowErrorMessage && (
                  <p className={s.error}>
                    * cообщение не доставлено, пожалуйста попробуйте снова позже, или напишите данное сообщение напрямую telegram-боту
                  </p>
                )}
                {isShowErrorEmptyMessage && (
                    <p className={s.error}>
                    * сообщение должно состоять минимум из пяти символов
                  </p>
                )}

            <p className={s.miniText}>
              * к вашему вопросу автоматически прикрепляется номер/id вопроса, на котором сейчас находитесь, и код в редакторе (эти данные можете в своем сообщении не указывать)
              </p>

                <div className={s.button_container}>
                  <Button
                    // onClickButton={startGameOrContinue}
                    text="Отправить вопрос"
                    bigButton={true}
                    type="submit"
                  />
                </div>
              </form>
             
            </div>
            {/* form before success submit - end */}
          </>
        )}
      </section>
    </ModalWrapper>
  );
};

export default ModalHelp;
