import React, { Component, createRef } from "react";
// компоненты из библиотек:
import { ToastContainer, toast } from "react-toastify";
// cтили:
import styles from "./MainPage.module.css";
import "react-toastify/dist/ReactToastify.css";
// компоненты созданные:
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ButtonHideTheory from "../../components/ButtonHideTheory/ButtonHideTheory";
import TheoryAndTaskOrLayout from "../../components/TheoryAndTaskOrLayout/TheoryAndTaskOrLayout";
import TaskButtons from "../../components/TaskButtons/TaskButtons";
import CongratsModal from "../../components/CongratsModal/CongratsModal";
import ErrorStub from "../../components/ErrorStub/ErrorStub";
import ErrorInvalidTokenOrBlock from "../../components/ErrorInvalidTokenOrBlock/ErrorInvalidTokenOrBlock";
import ErrorServerStub from "../../components/ErrorServerStub/ErrorServerStub";
import CodeEditors from "../../components/CodeEditors/CodeEditors";
import ModalRefreshQuestion from "../../components/ModalRefreshQuestion/ModalRefreshQuestion";
import ModalAutotranslator from "../../components/ModalAutotranslator/ModalAutotranslator";
import ResultArea from "../../components/ResultArea/ResultArea";
import ButtonHelp from "../../components/ButtonHelp/ButtonHelp";
import ModalHelp from "../../components/ModalHelp/ModalHelp";
import Loader from "../../components/Loader/Loader";
import ErrorServerRefreshStub from "../../components/ErrorServerRefreshStub/ErrorServerRefreshStub";
import ResizableWrapper from "../../components/ResizableWrapper/ResizableWrapper";
// прочие вспомогательные инструменты:
import * as API from "../../services/api";
// стили для codemirror
import "codemirror/lib/codemirror.css";
import "../../utils/materialCodeMirror.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/htmlmixed/htmlmixed";

//const timerDelayInMs = 600000; // 10 minutes
const timerDelayInMs = 720;

let shouldAvailabilityAutotranslator = false;
const waitTimeInMs = 1000;
let availabilityAutotranslatorTimer = waitTimeInMs;
let timerAvailabilityAutotranslatorId;

class MainPage extends Component {
  state = {
    currentTaskId: "",
    blockTasksArr: "",
    passedTasks: "",
    token: "",
    block: "",

    valueCode: "",
    htmlDescription: "",
    metadata: "",
    passed: "", // выполнена ли текущая задача или нет
    blockIndex: 0, // это номер дня марафоне в хедере и в поздравительной модалке
    // появление пропадание теории с заданием:
    isTheoryAndTaskShow: true,
    // модальное окно с поздравлениями:
    isCongratsModalOpen: false,

    // время старта для таймера:
    timerEndTime: Date.now() + timerDelayInMs,
    // показываем ли таймер на текущем вопросе:
    showTimer: true,
    // показать компонент ошибки (не корректная ссылка или блок в query parametrs строки или юзер не участник марафона):
    errorShow: false,
    // показать компонент ошибки когда токен или блок не валиден:
    errorInvalidTokenOrBlock: false,
    // для фиксации времени прохождения пользователем текущего вопроса:
    startTime: null,

    // буль на случай когда лежит сервер
    isServerError: false,
    // для модальных окон после 5ти дней о том есть ли нерешенные задания в каких то днях или марафон полностью завершен
    nonFinishedDays: null,
    finishedLastDay: false,
    passedAllTasks: false,
    // лоадер
    showLoader: false,

    // для подсветки или отключения конкретных строчек в редакторе кода
    notEditableCodeBlocks: [],
    codeHlLines: [],

    isRefreshCurrentQuestion: false,

    codeCursor: null,

    showModalRefreshQuestion: false,
    showModalAutotranslator: false,

    successConditions: [],
    failedConditions: [],
    checkMetadata: {},
    taskSuccessfullyPassed: null,

    isShowResultArea: true,
    isShowModalHelp: false,
    isShowErrorServerRefreshStub: false,

    speedResult: null,
  };

  theoryAndTaskRef = createRef();
  // refs for translateModal:
  headerTextLogoRef = createRef();
  headerTextQuestionRef = createRef();
  footerTextRef = createRef();

  componentDidMount() {
    const { location } = this.props;
    const { valueCode } = this.state;
    if (location.search && valueCode === "") {
      const token = new URLSearchParams(location.search).get("token");
      const block = new URLSearchParams(location.search).get("block");
      this.getCurrentBlockAndCurrentTask(token, block);
      this.showResultArea();
    } else {
      this.setState({ errorShow: true });
    }

    // checkAvailabilityAutotranslator logic - start
    timerAvailabilityAutotranslatorId = setInterval(() => {
      if (shouldAvailabilityAutotranslator) {
        availabilityAutotranslatorTimer -= 100;

        if (availabilityAutotranslatorTimer <= 0) {
          shouldAvailabilityAutotranslator = false;
          availabilityAutotranslatorTimer = waitTimeInMs;

          // monitoring availability autotranslator:
          this.checkAvailabilityAutotranslator();
        }
      }
    }, 100);
    // checkAvailabilityAutotranslator logic - end

    // monitoring availability autotranslator - start
    setTimeout(() => {
      this.checkAvailabilityAutotranslator();
    }, 2000);
    // monitoring availability autotranslator - end
  }

  componentDidUpdate(prevProps, prevState) {
    const { valueCode, currentTaskId } = this.state;
    const { clientWidth } = this.props;
    if (
      (prevState.valueCode !== valueCode && clientWidth > 900) ||
      (prevState.currentTaskId !== currentTaskId && clientWidth > 900)
    ) {
      shouldAvailabilityAutotranslator = true;
      availabilityAutotranslatorTimer = waitTimeInMs;
    }

    const { blockTasksArr } = this.state;
    if (
      prevState.clientWidth !== clientWidth &&
      prevState.clientWidth < 900 &&
      blockTasksArr === "" &&
      valueCode === ""
    ) {
      const { location } = this.props;
      if (location.search) {
        const token = new URLSearchParams(location.search).get("token");
        const block = new URLSearchParams(location.search).get("block");
        this.getCurrentBlockAndCurrentTask(token, block);
      } else {
        this.setState({ errorShow: true });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(timerAvailabilityAutotranslatorId);
  }

  hideTimer = () => {
    this.setState({ showTimer: false });
  };

  forHighligthExampleInTheoryAndTaskSection = () => {
    document.querySelectorAll("pre code").forEach((block) => {
      window.hljs.highlightBlock(block);
    });
  };

  handleChangeCode = (value) => {
    this.setState({ valueCode: value });
  };

  getCurrentBlockAndCurrentTask = (token, block) => {
    API.getTasksBlockAndCurrentTask(token, block)
      .then((res) => {
        if (res.data.success) {
          this.setState(
            {
              currentTaskId: res.data.currentTask,
              blockTasksArr: res.data.blockTasks,
              passedTasks: res.data.passedTasks,
              token: token,
              blockIndex: res.data.blockIndex,
              errorInvalidTokenOrBlock: false,
              errorShow: false,
              isServerError: false,
              block: block,
            },
            () => {
              this.getTask();
            }
          );
        } else {
          this.setState({ errorInvalidTokenOrBlock: true });
          toast.error(res.data.message, {
            autoClose: 4000,
          });
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      });
  };

  getTask = () => {
    const { token, currentTaskId } = this.state;
    toast.dismiss();
    this.resetTaskCriteriaConditions();

    this.setState({ showLoader: true });
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueCode: res.data.initialCode,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              startTime: Date.now(),
              isServerError: false,
              notEditableCodeBlocks: res.data.notEditableJsBlocks,
              codeHlLines: res.data.codeHlLines,
              codeCursor: res.data.codeCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
              this.showResultArea();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  checkTest = () => {
    const { token, currentTaskId, startTime, valueCode } = this.state;
    this.setState({ showLoader: true });
    this.resetTaskCriteriaConditions();
    const solveTimeSeconds = Math.round((Date.now() - startTime) / 1000);
    API.checkTest(token, currentTaskId, solveTimeSeconds, valueCode)
      .then((res) => {
        this.setState({
          isServerError: false,
          successConditions: res.data.successConditions,
          failedConditions: res.data.failedConditions,
          taskSuccessfullyPassed: res.data.taskSuccessfullyPassed,
          checkMetadata: res.data.metadata,
        });
        this.showResultArea();
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  openCongratsModal = () => {
    const { token, currentTaskId } = this.state;

    this.setState({ showLoader: true });
    API.getTaskStatisticsRequest(token, currentTaskId)
      .then((res) => {
        this.setState({ speedResult: res.data.betterThanPercents });
      })
      .then(() => {
        this.setState({ isCongratsModalOpen: true });
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };
  closeCongratsModal = () => this.setState({ isCongratsModalOpen: false });

  getNextTask = () => {
    toast.dismiss();
    this.resetTaskCriteriaConditions();
    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== blockTasksArr.length - 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) + 1]
        : blockTasksArr[0];

    this.setState((prevState) => ({
      showLoader: true,
      passedTasks: prevState.passedTasks.includes(currentTaskId)
        ? [...prevState.passedTasks]
        : [...prevState.passedTasks, currentTaskId],
    }));
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueCode: res.data.initialCode,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              isServerError: false,
              notEditableCodeBlocks: res.data.notEditableJsBlocks,
              codeHlLines: res.data.codeHlLines,
              codeCursor: res.data.codeCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
              this.showResultArea();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  getPrevTask = () => {
    toast.dismiss();
    this.resetTaskCriteriaConditions();
    const { token, currentTaskId, blockTasksArr } = this.state;
    const nextTaskId =
      blockTasksArr.indexOf(currentTaskId) !== 1
        ? blockTasksArr[blockTasksArr.indexOf(currentTaskId) - 1]
        : blockTasksArr[0];

    this.setState({ showLoader: true });
    API.getTaskInfo(token, nextTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueCode: res.data.initialCode,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              isServerError: false,
              notEditableCodeBlocks: res.data.notEditableJsBlocks,
              codeHlLines: res.data.codeHlLines,
              codeCursor: res.data.codeCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
              this.showResultArea();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  getSpecificTask = (taskId) => {
    toast.dismiss();
    this.resetTaskCriteriaConditions();
    const { token } = this.state;

    this.setState({ showLoader: true });
    API.getTaskInfo(token, taskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              currentTaskId: res.data.taskId,
              valueCode: res.data.initialCode,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              timerEndTime: Date.now() + timerDelayInMs,
              showTimer: true,
              startTime: Date.now(),
              isServerError: false,
              notEditableCodeBlocks: res.data.notEditableJsBlocks,
              codeHlLines: res.data.codeHlLines,
              codeCursor: res.data.codeCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.callToastify();
              this.showResultArea();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  resetTaskCriteriaConditions = () => {
    this.setState({
      successConditions: [],
      failedConditions: [],
      taskSuccessfullyPassed: false,
      checkMetadata: {},
    });
  };

  toggleTheoryAndTask = () => {
    this.setState(
      (prevState) => ({
        isTheoryAndTaskShow: !prevState.isTheoryAndTaskShow,
      }),
      () => this.forHighligthExampleInTheoryAndTaskSection()
    );
  };

  callToastify = () => {
    const { passed } = this.state;
    if (passed) {
      toast.success("Это задание уже решено!", {
        autoClose: 4000,
      });
    }
  };

  refreshCurrentQuestion = () => {
    toast.dismiss();
    this.resetTaskCriteriaConditions();

    this.setState({ showLoader: true });
    const { token, currentTaskId } = this.state;
    API.getTaskInfo(token, currentTaskId)
      .then((res) => {
        if (res.data.htmlDescription) {
          this.setState(
            {
              valueCode: res.data.initialCode,
              htmlDescription: res.data.htmlDescription,
              metadata: res.data.metadata,
              passed: res.data.passed,
              isServerError: false,
              notEditableCodeBlocks: res.data.notEditableJsBlocks,
              codeHlLines: res.data.codeHlLines,
              codeCursor: res.data.codeCursor,
            },
            () => {
              this.forHighligthExampleInTheoryAndTaskSection();
              this.setState({ isRefreshCurrentQuestion: true });
              this.showResultArea();
            }
          );
        } else if (!res.data.success) {
          this.setState({
            isShowErrorServerRefreshStub: true,
          });
          return;
        }
      })
      .then(() => {
        this.setState({ isRefreshCurrentQuestion: false });
      })
      .catch(() => {
        this.setState({ isServerError: true });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  changeBackgroundInCodemirrorWithHtml = () => {
    const { metadata } = this.state;
    if (metadata && metadata.disableCodeEditor) {
      if (!document.querySelector("#js_box")) return;
      document
        .querySelector("#js_box")
        .querySelector(".CodeMirror").style.backgroundColor = "rgb(27, 57, 94)";
      document
        .querySelector("#js_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgb(27, 57, 94)";
    } else if (metadata && !metadata.disableCodeEditor) {
      if (!document.querySelector("#js_box")) return;
      document
        .querySelector("#js_box")
        .querySelector(".CodeMirror").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
      document
        .querySelector("#js_box")
        .querySelector(".CodeMirror-gutters").style.backgroundColor =
        "rgba(16, 33, 54, 1)";
    }
  };

  openModalRefreshQuestion = () => {
    this.setState({ showModalRefreshQuestion: true });
  };
  closeModalRefreshQuestion = () => {
    this.setState({ showModalRefreshQuestion: false });
  };

  checkAvailabilityAutotranslator = () => {
    if (
      !this.headerTextLogoRef.current ||
      !this.headerTextQuestionRef.current ||
      !this.footerTextRef.current
    )
      return;

    if (
      !this.headerTextLogoRef.current.textContent.includes(
        "Автопроверка домашних заданий по JavaScript"
      ) ||
      !this.headerTextQuestionRef.current.textContent.includes("Задача") ||
      !this.footerTextRef.current.textContent.includes(
        "Политика конфиденциальности"
      )
    ) {
      this.openModalAutotranslator();
    }
  };
  openModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: true });
  };
  closeModalAutotranslator = () => {
    this.setState({ showModalAutotranslator: false });
  };

  upValueCodeInState = (value) => {
    this.setState({ valueCode: value });
  };

  activityTimeMonitoringInCodeEditor = () => {
    const { startTime } = this.state;
    const thirtyMinutesInMs = 1800000;
    if (Date.now() - startTime >= thirtyMinutesInMs) {
      this.setState({ startTime: Date.now() });
    }
  };

  checkUserSeenHelpVideoForTask = () => {
    const { token, currentTaskId } = this.state;
    const eventName = "userSeenHelpVideoForTask";
    API.checkUserSeenHelpVideoForTask(token, eventName, currentTaskId);
  };

  showResultArea = () => {
    this.setState({ isShowResultArea: true });
    document
      .querySelector(".CodeMirror")
      .classList.remove("bigHeightCodeMirror");
  };
  hideResultArea = () => {
    this.setState({ isShowResultArea: false });
    document.querySelector(".CodeMirror").classList.add("bigHeightCodeMirror");
  };

  openModalHelp = () => {
    this.setState({ isShowModalHelp: true });
  };
  closeModalHelp = () => {
    this.setState({ isShowModalHelp: false });
  };

  render() {
    // if the server reboots, you need to wait 1-2 minutes and reload the page:
    const { isShowErrorServerRefreshStub } = this.state;
    if (isShowErrorServerRefreshStub) {
      return <ErrorServerRefreshStub />;
    }

    // если не работает сервер, заглушка
    const { isServerError } = this.state;
    if (isServerError) {
      return <ErrorServerStub />;
    }

    // если не валиден token или block:
    const { errorInvalidTokenOrBlock } = this.state;
    if (errorInvalidTokenOrBlock) {
      return (
        <>
          <ToastContainer />
          <ErrorInvalidTokenOrBlock />
        </>
      );
    }

    // Если в url нету query parameters:
    const { errorShow } = this.state;
    if (errorShow) {
      return <ErrorStub />;
    }

    //  меняем беграунд html редактора когда он должен быть неактивным на редактирование
    const { metadata } = this.state;
    if (metadata && metadata.disableCodeEditor) {
      this.changeBackgroundInCodemirrorWithHtml();
    }

    const { clientWidth } = this.props;
    const {
      block,
      valueCode,
      blockTasksArr,
      currentTaskId,
      // для модального окна с результатами:
      passed,
      // isTheoryAndTaskShow
      isTheoryAndTaskShow,
      isCongratsModalOpen,
      // время для таймера:
      timerEndTime,
      showTimer,
      // номер дня марафона в хедере
      blockIndex,

      showLoader,
      htmlDescription,

      isRefreshCurrentQuestion,

      notEditableCodeBlocks,
      codeHlLines,
      codeCursor,

      showModalRefreshQuestion,
      showModalAutotranslator,

      successConditions,
      failedConditions,
      taskSuccessfullyPassed,
      checkMetadata,

      passedTasks,
      isShowResultArea,
      isShowModalHelp,
      token,
      speedResult,
    } = this.state;

    const widthCodeAndResultSection = isTheoryAndTaskShow ? "43.5%" : "96%";

    const codeAndResultContainerMarginLeft =
      clientWidth < 900 ? "1px" : isTheoryAndTaskShow ? "25px" : "4%";

    return (
      <>
        <Header
          blockTasksArr={blockTasksArr}
          currentTaskId={currentTaskId}
          getPrevTask={this.getPrevTask}
          getNextTask={this.getNextTask}
          passed={passed}
          block={block}
          headerTextLogoRef={this.headerTextLogoRef}
          headerTextQuestionRef={this.headerTextQuestionRef}
          passedTasks={passedTasks}
          getSpecificTask={this.getSpecificTask}
        />
        <main className={styles.main}>
          <article className={styles.mainContent}>
            <ButtonHideTheory
              toggleTheoryAndTask={this.toggleTheoryAndTask}
              isTheoryAndTaskShow={isTheoryAndTaskShow}
              clientWidth={clientWidth}
            />

            {/* начало секции теории и задания */}
            {isTheoryAndTaskShow &&
              (clientWidth > 900 ? (
                <ResizableWrapper>
                  <section
                    className={styles.wrapper_TheoryAndTaskOrLayout}
                    ref={this.theoryAndTaskRef}
                  >
                    <ButtonHelp
                      onClickButton={this.openModalHelp}
                      text="Обратная связь"
                    />

                    <TheoryAndTaskOrLayout
                      isServerError={isServerError}
                      isTheoryAndTaskShow={isTheoryAndTaskShow}
                      htmlDescription={htmlDescription}
                    />
                  </section>
                </ResizableWrapper>
              ) : (
                <section
                  className={styles.wrapper_TheoryAndTaskOrLayout}
                  ref={this.theoryAndTaskRef}
                  style={{ width: "100%" }}
                >
                  <ButtonHelp
                    onClickButton={this.openModalHelp}
                    text="Обратная связь"
                  />

                  <TheoryAndTaskOrLayout
                    isServerError={isServerError}
                    isTheoryAndTaskShow={isTheoryAndTaskShow}
                    htmlDescription={htmlDescription}
                  />
                </section>
              ))}
            {/* конец секции теории и задания */}

            {/* начало секции кода и результата */}
            {clientWidth > 900 ? (
              // секция кода и результата десктоп версия - начало
              <section
                className={styles.codeAndResult_container}
                style={{
                  marginLeft: codeAndResultContainerMarginLeft,
                }}
              >
                <CodeEditors
                  valueCode={valueCode}
                  handleChangeCode={this.handleChangeCode}
                  metadata={metadata}
                  currentTaskId={currentTaskId}
                  isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                  notEditableCodeBlocks={notEditableCodeBlocks}
                  codeHlLines={codeHlLines}
                  codeCursor={codeCursor}
                  upValueCodeInState={this.upValueCodeInState}
                  activityTimeMonitoringInCodeEditor={
                    this.activityTimeMonitoringInCodeEditor
                  }
                  isShowResultArea={isShowResultArea}
                />

                <TaskButtons
                  checkTest={this.checkTest}
                  timerEndTime={timerEndTime}
                  metadata={metadata}
                  openModalRefreshQuestion={this.openModalRefreshQuestion}
                  // для анимации первого дня первого вопроса
                  blockIndex={blockIndex}
                  currentTaskId={currentTaskId}
                  blockTasksArr={blockTasksArr}
                  showTimer={showTimer}
                  hideTimer={this.hideTimer}
                  clientWidth={clientWidth}
                  isTheoryAndTaskShow={isTheoryAndTaskShow}
                  // percentageDoneTask={percentageDoneTask}
                  getNextTask={this.getNextTask}
                  openCongratsModal={this.openCongratsModal}
                  taskSuccessfullyPassed={taskSuccessfullyPassed}
                  checkUserSeenHelpVideoForTask={
                    this.checkUserSeenHelpVideoForTask
                  }
                  showResultArea={this.showResultArea}
                  hideResultArea={this.hideResultArea}
                  isShowResultArea={isShowResultArea}
                />
                {isShowResultArea && (
                  <ResultArea
                    successConditions={successConditions}
                    failedConditions={failedConditions}
                    checkMetadata={checkMetadata}
                  />
                )}
              </section>
              // секция кода и результата десктоп версия - конец
            ) : (
              // секция кода и результата мобильная версия - начало
              <section
                className={styles.codeAndResult_container}
                style={{
                  width: widthCodeAndResultSection,
                  marginLeft: codeAndResultContainerMarginLeft,
                }}
              >
                <CodeEditors
                  valueCode={valueCode}
                  handleChangeCode={this.handleChangeCode}
                  metadata={metadata}
                  currentTaskId={currentTaskId}
                  isRefreshCurrentQuestion={isRefreshCurrentQuestion}
                  notEditableCodeBlocks={notEditableCodeBlocks}
                  codeHlLines={codeHlLines}
                  codeCursor={codeCursor}
                  upValueCodeInState={this.upValueCodeInState}
                  activityTimeMonitoringInCodeEditor={
                    this.activityTimeMonitoringInCodeEditor
                  }
                  isShowResultArea={isShowResultArea}
                />

                <TaskButtons
                  checkTest={this.checkTest}
                  timerEndTime={timerEndTime}
                  metadata={metadata}
                  openModalRefreshQuestion={this.openModalRefreshQuestion}
                  // для анимации первого дня первого вопроса
                  blockIndex={blockIndex}
                  currentTaskId={currentTaskId}
                  blockTasksArr={blockTasksArr}
                  showTimer={showTimer}
                  hideTimer={this.hideTimer}
                  clientWidth={clientWidth}
                  isTheoryAndTaskShow={isTheoryAndTaskShow}
                  // percentageDoneTask={percentageDoneTask}
                  getNextTask={this.getNextTask}
                  openCongratsModal={this.openCongratsModal}
                  taskSuccessfullyPassed={taskSuccessfullyPassed}
                  checkUserSeenHelpVideoForTask={
                    this.checkUserSeenHelpVideoForTask
                  }
                  showResultArea={this.showResultArea}
                  hideResultArea={this.hideResultArea}
                  isShowResultArea={isShowResultArea}
                />
                {isShowResultArea && (
                  <ResultArea
                    successConditions={successConditions}
                    failedConditions={failedConditions}
                    checkMetadata={checkMetadata}
                  />
                )}
              </section>
              // секция кода и результата мобильная версия - конец
            )}
            {/* конец секции кода и результата */}

          </article>
        </main>
        <Footer footerTextRef={this.footerTextRef} />

        {isCongratsModalOpen && speedResult && (
          <CongratsModal
            onClose={this.closeCongratsModal}
            blockTasksArr={blockTasksArr}
            blockIndex={blockIndex}
            block={block}
            speedResult={speedResult}
          />
        )}

        <ToastContainer
          style={
            clientWidth < 900
              ? { top: "50%", width: "80%", margin: "0 auto" }
              : { top: "55px", marginTop: "-10px" }
          }
        />

        {showLoader && <Loader />}

        {showModalRefreshQuestion && (
          <ModalRefreshQuestion
            onClose={this.closeModalRefreshQuestion}
            refreshCurrentQuestion={this.refreshCurrentQuestion}
          />
        )}

        {showModalAutotranslator && (
          <ModalAutotranslator onClose={this.closeModalAutotranslator} />
        )}

        {isShowModalHelp && (
          <ModalHelp
            onClose={this.closeModalHelp}
            token={token}
            currentTaskId={currentTaskId}
            valueCode={valueCode}
          />
        )}
      </>
    );
  }
}

export default MainPage;
